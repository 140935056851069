import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;600;700&family=Roboto+Slab:wght@300;400;500&display=swap');

  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto Slab', 'Times New Roman', serif;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  *:focus {
    outline: 2px solid ${({ theme }) => theme.palette.primary.main};
    outline-offset: 2px;
  }

  .skip-link {
    position: absolute;
    top: -40px;
    left: 0;
    background: ${({ theme }) => theme.palette.primary.main};
    color: white;
    padding: 8px;
    z-index: 100;

    &:focus {
      top: 0;
    }
  }
`;

export default GlobalStyle;