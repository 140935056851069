// src/routes/index.js
import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import PrivateRoute from './PrivateRoute';

const HomePage = lazy(() => import('../pages/HomePage'));
const AboutPage = lazy(() => import('../pages/AboutPage'));
const FrameworkPage = lazy(() => import('../pages/FrameworkPage'));
const BlogPage = lazy(() => import('../pages/BlogPage'));
const BlogPostPage = lazy(() => import('../pages/BlogPostPage'));
const AdminDashboardPage = lazy(() => import('../pages/AdminDashboardPage'));
const BlogManagementPage = lazy(() => import('../pages/BlogManagementPage'));
const UserManagementPage = lazy(() => import('../pages/UserManagementPage'));
const CommunityPage = lazy(() => import('../pages/CommunityPage'));
const LearnPage = lazy(() => import('../pages/LearnPage'));
const CityRankingsPage = lazy(() => import('../pages/CityRankingsPage'));
const ComingSoonPage = lazy(() => import('../pages/ComingSoonPage'));
const ContactPage = lazy(() => import('../pages/ContactPage'));
const NotFoundPage = lazy(() => import('../pages/NotFoundPage'));
const LoginRegisterPage = lazy(() => import('../pages/LoginRegisterPage'));
const ProfilePage = lazy(() => import('../pages/ProfilePage'));

const AppRoutes = ({ setError }) => {
  return (
    <Suspense fallback={<CircularProgress />}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/framework" element={<FrameworkPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog/:id" element={<BlogPostPage />} />
        <Route path="/community" element={<CommunityPage />} />
        <Route path="/learn" element={<LearnPage />} />
        <Route path="/city-rankings" element={<CityRankingsPage />} />
        <Route path="/coming-soon" element={<ComingSoonPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/login" element={<LoginRegisterPage />} />
        <Route path="/profile" element={<PrivateRoute><ProfilePage /></PrivateRoute>} />
        <Route path="/admin" element={<PrivateRoute adminOnly><AdminDashboardPage /></PrivateRoute>} />
        <Route path="/admin/blog-management" element={<PrivateRoute adminOnly><BlogManagementPage /></PrivateRoute>} />
        <Route path="/admin/user-management" element={<PrivateRoute adminOnly><UserManagementPage /></PrivateRoute>} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;