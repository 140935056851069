// src/App.js
import React, { useState, useMemo, Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Box, CircularProgress } from '@mui/material';
import { createThemeWithMode } from './styles/theme';
import GlobalStyle from './styles/globalStyles';

import Header from './components/Header';
import Footer from './components/Footer';
import AppRoutes from './routes';
import { AuthProvider } from './contexts/AuthContext';
import './firebase/config'; // Import Firebase configuration
import { ErrorBoundary } from './services/errorHandlingService';
import ErrorMessage from './components/ErrorMessage';

function App() {
  const [mode, setMode] = useState('light');
  const [error, setError] = useState(null);

  const toggleMode = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  const theme = useMemo(() => createThemeWithMode(mode), [mode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyle theme={theme} />
      <AuthProvider>
        <Router>
          <ErrorBoundary>
            <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
              <a href="#main-content" className="skip-link">Skip to main content</a>
              <Header mode={mode} toggleMode={toggleMode} />
              <Box component="main" id="main-content" sx={{ flexGrow: 1, p: { xs: 2, md: 3 } }}>
                {error && <ErrorMessage message={error} />}
                <Suspense fallback={<CircularProgress />}>
                  <AppRoutes setError={setError} />
                </Suspense>
              </Box>
              <Footer />
            </Box>
          </ErrorBoundary>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;