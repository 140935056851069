// src/contexts/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase/config';
import { logError, ErrorTypes } from '../services/errorHandlingService';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userRoles, setUserRoles] = useState({ isAdmin: false, isAuthor: false, isSubscriber: false });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserRoles({
              isAdmin: userData.isAdmin === true,
              isAuthor: userData.isAuthor === true,
              isSubscriber: userData.isSubscriber === true
            });
          } else {
            setUserRoles({ isAdmin: false, isAuthor: false, isSubscriber: false });
          }
        } catch (error) {
          logError(error, ErrorTypes.AUTH);
          setError("Failed to fetch user roles. Please try logging in again.");
          setUserRoles({ isAdmin: false, isAuthor: false, isSubscriber: false });
        }
      } else {
        setUserRoles({ isAdmin: false, isAuthor: false, isSubscriber: false });
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const logout = async () => {
    try {
      await signOut(auth);
      setCurrentUser(null);
      setUserRoles({ isAdmin: false, isAuthor: false, isSubscriber: false });
    } catch (error) {
      logError(error, ErrorTypes.AUTH);
      setError("Failed to log out. Please try again.");
    }
  };

  const clearError = () => setError(null);

  const value = {
    currentUser,
    userRoles,
    logout,
    error,
    clearError,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}