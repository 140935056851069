import { createTheme } from '@mui/material/styles';

const colors = {
  teal: {
    main: '#2D8884',
    light: '#62B6B0',
    dark: '#1B5955',
  },
  orange: {
    main: '#F79E5A',
    light: '#FFC090',
    dark: '#D97125',
  },
  success: '#4caf50',
  warning: '#ff9800',
  error: '#f44336',
};

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          primary: {
            main: colors.teal.main,
            light: colors.teal.light,
            dark: colors.teal.dark,
          },
          secondary: {
            main: colors.orange.main,
            light: colors.orange.light,
            dark: colors.orange.dark,
          },
          background: {
            default: '#ffffff',
            paper: '#f5f5f5',
          },
          text: {
            primary: '#000000',
            secondary: '#4a4a4a',
          },
        }
      : {
          primary: {
            main: colors.teal.light,
            light: colors.teal.main,
            dark: colors.teal.dark,
          },
          secondary: {
            main: colors.orange.light,
            light: colors.orange.main,
            dark: colors.orange.dark,
          },
          background: {
            default: '#121212',
            paper: '#1e1e1e',
          },
          text: {
            primary: '#ffffff',
            secondary: '#b0b0b0',
          },
        }),
    success: {
      main: colors.success,
    },
    warning: {
      main: colors.warning,
    },
    error: {
      main: colors.error,
    },
  },
  typography: {
    fontFamily: '"Playfair Display", "Times New Roman", serif',
    h1: {
      fontWeight: 700,
      letterSpacing: '-0.01562em',
      fontSize: '2.5rem',
      '@media (min-width:600px)': {
        fontSize: '3.5rem',
      },
    },
    h2: {
      fontWeight: 700,
      letterSpacing: '-0.00833em',
      fontSize: '2rem',
      '@media (min-width:600px)': {
        fontSize: '2.75rem',
      },
    },
    h3: {
      fontWeight: 600,
      letterSpacing: '0em',
      fontSize: '1.75rem',
      '@media (min-width:600px)': {
        fontSize: '2.25rem',
      },
    },
    h4: {
      fontWeight: 600,
      letterSpacing: '0.00735em',
      fontSize: '1.5rem',
      '@media (min-width:600px)': {
        fontSize: '1.75rem',
      },
    },
    h5: {
      fontWeight: 600,
      letterSpacing: '0em',
      fontSize: '1.25rem',
      '@media (min-width:600px)': {
        fontSize: '1.5rem',
      },
    },
    h6: {
      fontWeight: 600,
      letterSpacing: '0.0075em',
      fontSize: '1rem',
      '@media (min-width:600px)': {
        fontSize: '1.25rem',
      },
    },
    subtitle1: {
      fontFamily: '"Roboto Slab", "Times New Roman", serif',
    },
    subtitle2: {
      fontFamily: '"Roboto Slab", "Times New Roman", serif',
      fontWeight: 500,
    },
    body1: {
      fontFamily: '"Roboto Slab", "Times New Roman", serif',
    },
    body2: {
      fontFamily: '"Roboto Slab", "Times New Roman", serif',
    },
    button: {
      fontFamily: '"Roboto Slab", "Times New Roman", serif',
      fontWeight: 500,
      letterSpacing: '0.02857em',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#000000',
          color: '#ffffff',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: ({ theme }) => ({
          backgroundColor: theme.palette.primary.main,
          color: '#ffffff',
          '&:hover': {
            backgroundColor: theme.palette.secondary.main,
          },
        }),
        outlined: ({ theme }) => ({
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,
          '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main,
            color: '#ffffff',
          },
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.background.paper,
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.main,
          '&:hover': {
            color: theme.palette.secondary.main,
          },
        }),
      },
    },
  },
});

export const createThemeWithMode = (mode) => createTheme(getDesignTokens(mode));

const theme = createTheme(getDesignTokens('light'));

export default theme;