// src/components/ErrorMessage.js

import React from 'react';
import { Alert, AlertTitle } from '@mui/material';

const ErrorMessage = ({ message, severity = 'error' }) => {
  return (
    <Alert severity={severity} sx={{ mt: 2, mb: 2 }}>
      <AlertTitle>{severity === 'error' ? 'Error' : 'Warning'}</AlertTitle>
      {message}
    </Alert>
  );
};

export default ErrorMessage;