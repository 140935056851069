// src/components/Header.js
import React, { useState, useCallback, useMemo } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Button, Box, Drawer, List, ListItem, ListItemText, useMediaQuery, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { styled, useTheme } from '@mui/material/styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const NavLink = styled(Button)(({ theme }) => ({
  color: '#ffffff',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const Header = ({ mode, toggleMode }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser, userRoles, logout } = useAuth();

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen((prevState) => !prevState);
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = useCallback(async () => {
    try {
      await logout();
      navigate('/');
      handleClose();
    } catch (error) {
      console.error('Logout error:', error);
    }
  }, [navigate, logout]);

  const handleProfile = () => {
    navigate('/profile');
    handleClose();
    if (isMobile) {
      handleDrawerToggle();
    }
  };

  const navItems = useMemo(() => [
    { title: 'Home', path: '/' },
    { title: 'About', path: '/about' },
    { title: 'Framework', path: '/framework' },
    { title: 'Blog', path: '/blog' },
    { title: 'Community', path: '/community' },
    { title: 'Learn', path: '/learn' },
    ...(userRoles.isAdmin ? [{ title: 'Admin', path: '/admin' }] : []),
  ], [userRoles.isAdmin]);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2, color: '#ffffff' }}>
        URBNIA
      </Typography>
      <List>
        {navItems.map((item) => (
          <ListItem key={item.title} disablePadding>
            <NavLink
              component={Link}
              to={item.path}
              sx={{
                width: '100%',
                justifyContent: 'center',
                color: '#ffffff',
                '&.Mui-selected': {
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
              }}
              selected={location.pathname === item.path}
            >
              <ListItemText primary={item.title} />
            </NavLink>
          </ListItem>
        ))}
        {currentUser && (
          <ListItem disablePadding>
            <NavLink
              onClick={handleProfile}
              sx={{
                width: '100%',
                justifyContent: 'center',
                color: '#ffffff',
              }}
            >
              <ListItemText primary="Profile" />
            </NavLink>
          </ListItem>
        )}
        {currentUser && (
          <ListItem disablePadding>
            <NavLink
              onClick={handleLogout}
              sx={{
                width: '100%',
                justifyContent: 'center',
                color: '#ffffff',
              }}
            >
              <ListItemText primary="Logout" />
            </NavLink>
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <AppBar position="static">
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h6" component={Link} to="/" sx={{ textDecoration: 'none', color: 'inherit' }}>
          URBNIA
        </Typography>

        {!isMobile && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexGrow: 1 }}>
            {navItems.map((item) => (
              <NavLink
                key={item.title}
                component={Link}
                to={item.path}
                selected={location.pathname === item.path}
              >
                {item.title}
              </NavLink>
            ))}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircleIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {currentUser ? (
                <>
                  <MenuItem onClick={handleProfile}>Profile</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </>
              ) : (
                <>
                  <MenuItem component={Link} to="/login">Login</MenuItem>
                  <MenuItem component={Link} to="/login?tab=register">Register</MenuItem>
                </>
              )}
            </Menu>
          </Box>
        )}

        <IconButton onClick={toggleMode} color="inherit" aria-label="Toggle dark mode">
          {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>

        {isMobile && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ ml: 2 }}
          >
            <MenuIcon />
          </IconButton>
        )}
      </Toolbar>

      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', lg: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: 240,
            backgroundColor: '#000000',
          },
        }}
      >
        {drawer}
      </Drawer>
    </AppBar>
  );
};

export default Header;