// src/services/errorHandlingService.js

// Import Firebase auth errors
import { AuthErrorCodes } from 'firebase/auth';
import React from 'react';


// Define custom error types
export const ErrorTypes = {
  AUTH: 'auth',
  FORM: 'form',
  API: 'api',
  UNKNOWN: 'unknown',
};

// Map Firebase auth error codes to user-friendly messages
const authErrorMessages = {
  [AuthErrorCodes.USER_DELETED]: 'No account found with this email. Please sign up.',
  [AuthErrorCodes.USER_DISABLED]: 'This account has been disabled. Please contact support.',
  [AuthErrorCodes.EMAIL_EXISTS]: 'An account already exists with this email address.',
  [AuthErrorCodes.INVALID_EMAIL]: 'Please enter a valid email address.',
  [AuthErrorCodes.WEAK_PASSWORD]: 'Password should be at least 6 characters long.',
  [AuthErrorCodes.WRONG_PASSWORD]: 'Incorrect password. Please try again.',
  // Add more mappings as needed
};

// Function to get user-friendly error message
export const getUserFriendlyErrorMessage = (error, errorType = ErrorTypes.UNKNOWN) => {
  console.error('Original error:', error); // Log the original error for debugging

  switch (errorType) {
    case ErrorTypes.AUTH:
      return authErrorMessages[error.code] || 'An authentication error occurred. Please try again.';
    case ErrorTypes.FORM:
      return error.message || 'Please check your input and try again.';
    case ErrorTypes.API:
      return 'An error occurred while communicating with the server. Please try again later.';
    default:
      return 'An unexpected error occurred. Please try again or contact support.';
  }
};

// Function to log errors (can be expanded to send logs to a server)
export const logError = (error, errorType) => {
  console.error(`Error of type ${errorType}:`, error);
  // Here you could add logic to send error logs to a server
};

// Error boundary class component

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logError(error, ErrorTypes.UNKNOWN);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong. Please refresh the page or contact support.</h1>;
    }

    return this.props.children;
  }
}